.masthead {
  height: 100vh;
  min-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.masthead h1 {
  font-family: "Spartan", sans-serif;
  color: white;
}

.masthead p {
  font-size: 1.2rem;
  color: #efc06c;
  font-weight: bold;
  text-shadow: 1px 1px black;
}

.masthead img {
  border-radius: 10px;
}

@media only screen and (max-width: 1000px) {
}
