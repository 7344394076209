@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@5003&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@800&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(/static/media/background2.bc0fb9a5.png);
  background-color: #18528f;
  /* background-color: #213c00; */
  color: #ffffff;
}

h1 {
  font-size: 4rem;
}

.t-shadow {
  text-shadow: 2px 2px #000000;
}

@media only screen and (max-width: 1300px) {
  h1 {
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: 1115px) {
  .faq,
  .about {
    scroll-margin-top: 3rem;
  }

  .roadmap {
    scroll-margin-top: 4rem;
  }
}

/* Customize your main colors in :root variables */
:root {
  --main-background-color: #343a50;
  --card-background-color: #51576d;
  --card-background-lighter-color: #7a7f92;
  --main-text-color: #f7f6f4;
  --title-text-color: #3cba8b;
}

h2,
p {
  color: #f7f6f4;
  color: var(--main-text-color);
}

h3 {
  font-weight: normal;
}

.masthead {
  height: 100vh;
  min-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.masthead h1 {
  font-family: "Spartan", sans-serif;
  color: white;
}

.masthead p {
  font-size: 1.2rem;
  color: #efc06c;
  font-weight: bold;
  text-shadow: 1px 1px black;
}

.masthead img {
  border-radius: 10px;
}

@media only screen and (max-width: 1000px) {
}

