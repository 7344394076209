@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@5003&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@800&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/images/background2.png");
  background-color: #18528f;
  /* background-color: #213c00; */
  color: #ffffff;
}

h1 {
  font-size: 4rem;
}

.t-shadow {
  text-shadow: 2px 2px #000000;
}

@media only screen and (max-width: 1300px) {
  h1 {
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: 1115px) {
  .faq,
  .about {
    scroll-margin-top: 3rem;
  }

  .roadmap {
    scroll-margin-top: 4rem;
  }
}
